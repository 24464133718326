import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _66f34522 = () => interopDefault(import('../pages/about-us/index.vue' /* webpackChunkName: "pages/about-us/index" */))
const _c94a5874 = () => interopDefault(import('../pages/about-us-old.vue' /* webpackChunkName: "pages/about-us-old" */))
const _d74a30b2 = () => interopDefault(import('../pages/ambassadors/index.vue' /* webpackChunkName: "pages/ambassadors/index" */))
const _6cf480a8 = () => interopDefault(import('../pages/analista-de-dados-landing/index.vue' /* webpackChunkName: "pages/analista-de-dados-landing/index" */))
const _07369019 = () => interopDefault(import('../pages/career-center-company/index.vue' /* webpackChunkName: "pages/career-center-company/index" */))
const _76eba8f0 = () => interopDefault(import('../pages/career-center-company-old.vue' /* webpackChunkName: "pages/career-center-company-old" */))
const _00fdfbdc = () => interopDefault(import('../pages/career-center-students/index.vue' /* webpackChunkName: "pages/career-center-students/index" */))
const _262775ae = () => interopDefault(import('../pages/career-center-students-old.vue' /* webpackChunkName: "pages/career-center-students-old" */))
const _2ab7cc1d = () => interopDefault(import('../pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _5ef69150 = () => interopDefault(import('../pages/ebac-experimental.vue' /* webpackChunkName: "pages/ebac-experimental" */))
const _1f50aeb4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4343472a = () => interopDefault(import('../pages/ilustracao-landing/index.vue' /* webpackChunkName: "pages/ilustracao-landing/index" */))
const _6a628ca6 = () => interopDefault(import('../pages/index1.vue' /* webpackChunkName: "pages/index1" */))
const _685266d0 = () => interopDefault(import('../pages/sale/index.vue' /* webpackChunkName: "pages/sale/index" */))
const _2f06112b = () => interopDefault(import('../pages/ui-kit.vue' /* webpackChunkName: "pages/ui-kit" */))
const _9c74b3d4 = () => interopDefault(import('../pages/ui-kit1.vue' /* webpackChunkName: "pages/ui-kit1" */))
const _56cde08a = () => interopDefault(import('../pages/vacancies.vue' /* webpackChunkName: "pages/vacancies" */))
const _7d6efeea = () => interopDefault(import('../pages/analista-de-dados-landing/thankyou.vue' /* webpackChunkName: "pages/analista-de-dados-landing/thankyou" */))
const _5ffa3f66 = () => interopDefault(import('../pages/ilustracao-landing/thankyou.vue' /* webpackChunkName: "pages/ilustracao-landing/thankyou" */))
const _6b7d4a7c = () => interopDefault(import('../pages/loan-request/result.vue' /* webpackChunkName: "pages/loan-request/result" */))
const _75b278ce = () => interopDefault(import('../pages/offers/success.vue' /* webpackChunkName: "pages/offers/success" */))
const _13accd15 = () => interopDefault(import('../pages/payment/fail.vue' /* webpackChunkName: "pages/payment/fail" */))
const _0c6c7f28 = () => interopDefault(import('../pages/payment/generate.vue' /* webpackChunkName: "pages/payment/generate" */))
const _7707265c = () => interopDefault(import('../pages/payment/success.vue' /* webpackChunkName: "pages/payment/success" */))
const _7e74b0f8 = () => interopDefault(import('../pages/sale/new-design/index.vue' /* webpackChunkName: "pages/sale/new-design/index" */))
const _57373c1f = () => interopDefault(import('../pages/sale/thankyou.vue' /* webpackChunkName: "pages/sale/thankyou" */))
const _8ea87322 = () => interopDefault(import('../pages/blog/_direction.vue' /* webpackChunkName: "pages/blog/_direction" */))
const _4ae564eb = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _47faa2de = () => interopDefault(import('../pages/course-page/_slug.vue' /* webpackChunkName: "pages/course-page/_slug" */))
const _051da062 = () => interopDefault(import('../pages/-coursesTopic' /* webpackChunkName: "" */))
const _7e069d5f = () => interopDefault(import('../pages/loan-request/_loanRequest.vue' /* webpackChunkName: "pages/loan-request/_loanRequest" */))
const _53d3d095 = () => interopDefault(import('../pages/loan-request/_paymentPage.vue' /* webpackChunkName: "pages/loan-request/_paymentPage" */))
const _7ba47f89 = () => interopDefault(import('../pages/offers-updated/_offer.vue' /* webpackChunkName: "pages/offers-updated/_offer" */))
const _11f32f0c = () => interopDefault(import('../pages/offers/_offer/index.vue' /* webpackChunkName: "pages/offers/_offer/index" */))
const _74c3d2ec = () => interopDefault(import('../pages/sale/_sale.vue' /* webpackChunkName: "pages/sale/_sale" */))
const _da502f8a = () => interopDefault(import('../pages/webinars/_directions.vue' /* webpackChunkName: "pages/webinars/_directions" */))
const _e062e256 = () => interopDefault(import('../pages/webinars/_slug/index.vue' /* webpackChunkName: "pages/webinars/_slug/index" */))
const _5ff6756e = () => interopDefault(import('../pages/offers/_offer/success/index.vue' /* webpackChunkName: "pages/offers/_offer/success/index" */))
const _9b435934 = () => interopDefault(import('../pages/-webinarSale' /* webpackChunkName: "" */))
const _420e11cf = () => interopDefault(import('../pages/-webinarSaleThanks' /* webpackChunkName: "" */))
const _17539ff2 = () => interopDefault(import('../pages/_courses.vue' /* webpackChunkName: "pages/_courses" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _66f34522,
    name: "about-us___pt-br"
  }, {
    path: "/about-us-old",
    component: _c94a5874,
    name: "about-us-old___pt-br"
  }, {
    path: "/ambassadors",
    component: _d74a30b2,
    name: "ambassadors___pt-br"
  }, {
    path: "/analista-de-dados-landing",
    component: _6cf480a8,
    name: "analista-de-dados-landing___pt-br"
  }, {
    path: "/career-center-company",
    component: _07369019,
    name: "career-center-company___pt-br"
  }, {
    path: "/career-center-company-old",
    component: _76eba8f0,
    name: "career-center-company-old___pt-br"
  }, {
    path: "/career-center-students",
    component: _00fdfbdc,
    name: "career-center-students___pt-br"
  }, {
    path: "/career-center-students-old",
    component: _262775ae,
    name: "career-center-students-old___pt-br"
  }, {
    path: "/cookie-policy",
    component: _2ab7cc1d,
    name: "cookie-policy___pt-br"
  }, {
    path: "/ebac-experimental",
    component: _5ef69150,
    name: "ebac-experimental___pt-br"
  }, {
    path: "/es-mx",
    component: _1f50aeb4,
    name: "index___es-mx"
  }, {
    path: "/ilustracao-landing",
    component: _4343472a,
    name: "ilustracao-landing___pt-br"
  }, {
    path: "/index1",
    component: _6a628ca6,
    name: "index1___pt-br"
  }, {
    path: "/sale",
    component: _685266d0,
    name: "sale___pt-br"
  }, {
    path: "/ui-kit",
    component: _2f06112b,
    name: "ui-kit___pt-br"
  }, {
    path: "/ui-kit1",
    component: _9c74b3d4,
    name: "ui-kit1___pt-br"
  }, {
    path: "/vacancies",
    component: _56cde08a,
    name: "vacancies___pt-br"
  }, {
    path: "/analista-de-dados-landing/thankyou",
    component: _7d6efeea,
    name: "analista-de-dados-landing-thankyou___pt-br"
  }, {
    path: "/es-mx/about-us",
    component: _66f34522,
    name: "about-us___es-mx"
  }, {
    path: "/es-mx/about-us-old",
    component: _c94a5874,
    name: "about-us-old___es-mx"
  }, {
    path: "/es-mx/ambassadors",
    component: _d74a30b2,
    name: "ambassadors___es-mx"
  }, {
    path: "/es-mx/analista-de-dados-landing",
    component: _6cf480a8,
    name: "analista-de-dados-landing___es-mx"
  }, {
    path: "/es-mx/career-center-company",
    component: _07369019,
    name: "career-center-company___es-mx"
  }, {
    path: "/es-mx/career-center-company-old",
    component: _76eba8f0,
    name: "career-center-company-old___es-mx"
  }, {
    path: "/es-mx/career-center-students",
    component: _00fdfbdc,
    name: "career-center-students___es-mx"
  }, {
    path: "/es-mx/career-center-students-old",
    component: _262775ae,
    name: "career-center-students-old___es-mx"
  }, {
    path: "/es-mx/cookie-policy",
    component: _2ab7cc1d,
    name: "cookie-policy___es-mx"
  }, {
    path: "/es-mx/ebac-experimental",
    component: _5ef69150,
    name: "ebac-experimental___es-mx"
  }, {
    path: "/es-mx/ilustracao-landing",
    component: _4343472a,
    name: "ilustracao-landing___es-mx"
  }, {
    path: "/es-mx/index1",
    component: _6a628ca6,
    name: "index1___es-mx"
  }, {
    path: "/es-mx/sale",
    component: _685266d0,
    name: "sale___es-mx"
  }, {
    path: "/es-mx/ui-kit",
    component: _2f06112b,
    name: "ui-kit___es-mx"
  }, {
    path: "/es-mx/ui-kit1",
    component: _9c74b3d4,
    name: "ui-kit1___es-mx"
  }, {
    path: "/es-mx/vacancies",
    component: _56cde08a,
    name: "vacancies___es-mx"
  }, {
    path: "/ilustracao-landing/thankyou",
    component: _5ffa3f66,
    name: "ilustracao-landing-thankyou___pt-br"
  }, {
    path: "/loan-request/result",
    component: _6b7d4a7c,
    name: "loan-request-result___pt-br"
  }, {
    path: "/offers/success",
    component: _75b278ce,
    name: "offers-success___pt-br"
  }, {
    path: "/payment/fail",
    component: _13accd15,
    name: "payment-fail___pt-br"
  }, {
    path: "/payment/generate",
    component: _0c6c7f28,
    name: "payment-generate___pt-br"
  }, {
    path: "/payment/success",
    component: _7707265c,
    name: "payment-success___pt-br"
  }, {
    path: "/sale/new-design",
    component: _7e74b0f8,
    name: "sale-new-design___pt-br"
  }, {
    path: "/sale/thankyou",
    component: _57373c1f,
    name: "sale-thankyou___pt-br"
  }, {
    path: "/es-mx/analista-de-dados-landing/thankyou",
    component: _7d6efeea,
    name: "analista-de-dados-landing-thankyou___es-mx"
  }, {
    path: "/es-mx/ilustracao-landing/thankyou",
    component: _5ffa3f66,
    name: "ilustracao-landing-thankyou___es-mx"
  }, {
    path: "/es-mx/loan-request/result",
    component: _6b7d4a7c,
    name: "loan-request-result___es-mx"
  }, {
    path: "/es-mx/offers/success",
    component: _75b278ce,
    name: "offers-success___es-mx"
  }, {
    path: "/es-mx/payment/fail",
    component: _13accd15,
    name: "payment-fail___es-mx"
  }, {
    path: "/es-mx/payment/generate",
    component: _0c6c7f28,
    name: "payment-generate___es-mx"
  }, {
    path: "/es-mx/payment/success",
    component: _7707265c,
    name: "payment-success___es-mx"
  }, {
    path: "/es-mx/sale/new-design",
    component: _7e74b0f8,
    name: "sale-new-design___es-mx"
  }, {
    path: "/es-mx/sale/thankyou",
    component: _57373c1f,
    name: "sale-thankyou___es-mx"
  }, {
    path: "/es-mx/blog/:direction?",
    component: _8ea87322,
    name: "blog-direction___es-mx"
  }, {
    path: "/es-mx/blog/:slug?",
    component: _4ae564eb,
    name: "blog-slug___es-mx"
  }, {
    path: "/es-mx/course-page/:slug?",
    component: _47faa2de,
    name: "course-page-slug___es-mx"
  }, {
    path: "/es-mx/cursos/:topic",
    component: _051da062,
    name: "coursesTopic___es-mx"
  }, {
    path: "/es-mx/loan-request/:loanRequest?",
    component: _7e069d5f,
    name: "loan-request-loanRequest___es-mx"
  }, {
    path: "/es-mx/loan-request/:paymentPage?",
    component: _53d3d095,
    name: "loan-request-paymentPage___es-mx"
  }, {
    path: "/es-mx/offers-updated/:offer?",
    component: _7ba47f89,
    name: "offers-updated-offer___es-mx"
  }, {
    path: "/es-mx/offers/:offer",
    component: _11f32f0c,
    name: "offers-offer___es-mx"
  }, {
    path: "/es-mx/sale/:sale",
    component: _74c3d2ec,
    name: "sale-sale___es-mx"
  }, {
    path: "/es-mx/webinars/:directions?",
    component: _da502f8a,
    name: "webinars-directions___es-mx"
  }, {
    path: "/es-mx/webinars/:slug",
    component: _e062e256,
    name: "webinars-slug___es-mx"
  }, {
    path: "/es-mx/offers/:offer?/success",
    component: _5ff6756e,
    name: "offers-offer-success___es-mx"
  }, {
    path: "/es-mx/webinars/:eventSlug/:saleSlug",
    component: _9b435934,
    name: "webinarSale___es-mx"
  }, {
    path: "/es-mx/webinars/:eventSlug/:saleSlug/thankyou",
    component: _420e11cf,
    name: "webinarSaleThanks___es-mx"
  }, {
    path: "/blog/:direction?",
    component: _8ea87322,
    name: "blog-direction___pt-br"
  }, {
    path: "/blog/:slug?",
    component: _4ae564eb,
    name: "blog-slug___pt-br"
  }, {
    path: "/course-page/:slug?",
    component: _47faa2de,
    name: "course-page-slug___pt-br"
  }, {
    path: "/cursos/:topic",
    component: _051da062,
    name: "coursesTopic___pt-br"
  }, {
    path: "/es-mx/:courses",
    component: _17539ff2,
    name: "courses___es-mx"
  }, {
    path: "/loan-request/:loanRequest?",
    component: _7e069d5f,
    name: "loan-request-loanRequest___pt-br"
  }, {
    path: "/loan-request/:paymentPage?",
    component: _53d3d095,
    name: "loan-request-paymentPage___pt-br"
  }, {
    path: "/offers-updated/:offer?",
    component: _7ba47f89,
    name: "offers-updated-offer___pt-br"
  }, {
    path: "/offers/:offer",
    component: _11f32f0c,
    name: "offers-offer___pt-br"
  }, {
    path: "/sale/:sale",
    component: _74c3d2ec,
    name: "sale-sale___pt-br"
  }, {
    path: "/webinars/:directions?",
    component: _da502f8a,
    name: "webinars-directions___pt-br"
  }, {
    path: "/webinars/:slug",
    component: _e062e256,
    name: "webinars-slug___pt-br"
  }, {
    path: "/offers/:offer?/success",
    component: _5ff6756e,
    name: "offers-offer-success___pt-br"
  }, {
    path: "/webinars/:eventSlug/:saleSlug",
    component: _9b435934,
    name: "webinarSale___pt-br"
  }, {
    path: "/webinars/:eventSlug/:saleSlug/thankyou",
    component: _420e11cf,
    name: "webinarSaleThanks___pt-br"
  }, {
    path: "/",
    component: _1f50aeb4,
    name: "index___pt-br"
  }, {
    path: "/:courses",
    component: _17539ff2,
    name: "courses___pt-br"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
